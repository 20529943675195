(function (window, document, $, undefined) {
  "use strict";
  const $window = $(window),
      $document = $(document);

  $.fn.equalizeHeights = function () {
      var $items = $(this),
          heightArray = [];
      if( !$items.length ){ return; }
      $items.height('auto');
      $items.each(function(index, elem){ heightArray.push( $(elem).height() ); });
      $items.height( Math.max.apply( Math, heightArray ) ); 
      return this;
  };
  

  Array.prototype.clean = function(deleteValue) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] == deleteValue) {         
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  if (!Array.prototype.includes) {
    Array.prototype.includes = function(search) {
      return $.inArray(search, this) < 0 ? false : true;
    };
  }

  if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
      value: function(value) {
  
        // Steps 1-2.
        if (this == null) {
          throw new TypeError('this is null or not defined');
        }
  
        var O = Object(this);
  
        // Steps 3-5.
        var len = O.length >>> 0;
  
        // Steps 6-7.
        var start = arguments[1];
        var relativeStart = start >> 0;
  
        // Step 8.
        var k = relativeStart < 0 ?
          Math.max(len + relativeStart, 0) :
          Math.min(relativeStart, len);
  
        // Steps 9-10.
        var end = arguments[2];
        var relativeEnd = end === undefined ?
          len : end >> 0;
  
        // Step 11.
        var final = relativeEnd < 0 ?
          Math.max(len + relativeEnd, 0) :
          Math.min(relativeEnd, len);
  
        // Step 12.
        while (k < final) {
          O[k] = value;
          k++;
        }
  
        // Step 13.
        return O;
      }
    });
  }

  window.handler = () => {};

  window.handler.prototype = {
    onReadySetup(){
      this.$body = $('body');
      this.context = null;
      this.ajaxCall = window.API_URL;
      this.apiRemember = window.API_REMEMBER;
      this.apiCustomization = window.API_CUSTOMIZATION;
      this.apiInteractions = "/INTERACTIONS";
      this.apiDialogue = "/DIALOG";
      this.apiContextID = "/asistente"
      this.params = this.getQueryParams(document.location.search);
      this.cid = '';
      this.contextID = this.params.id,
      this.empresa = this.params.empresa,
      this.isCallingAjax = true;
      this.validateString = false;
      this.customization = {}

      this.getCustomization();
      this.eventsHandler( $('[data-func]') );

    },
    getCustomization(){
      const promise = $.ajax({
        type: 'POST',
        url: this.apiCustomization,
        crossDomain: true,
        data: JSON.stringify({
          tag: 'asistente-digital',
          empresa: this.empresa
        }),
        contentType: 'application/json;charset=UTF-8',
        dataType: 'json'
      });

      promise.done((response) => {
        this.customize(response.data);
        // this.setupFormContextId();
      });
    },
    customize(props){
      this.customization = props;

      const head = document.head,
              style = document.createElement('style');
        
      let css = '';
      css += '.assistant-title{ color: '+ props.color_titulos  +' }';
      css += '.assistant-subtitle{ color: '+ props.color_titulos  +' }';
      css += '.main-header{ background-color: '+ props.header_color_fondo  +' }';
      css += '.btn{ background-color: '+ props.color_botones  +' }';
      css += '.btn:hover{ background-color: '+ props.color_botones  +' }';
      css += '.btn.btn-light{ background-color: transparent; border-color: '+ props.color_botones +'; color: '+ props.color_botones +' }';
      css += '.btn.btn-light:hover{ background-color: '+ props.color_botones +'; color: #FFF }';
      css += '.btn.btn-light.active{ background-color: '+ props.color_botones +'; }';
      css += '.tooltip{ background-color: '+ props.color_botones  +' }';
      css += '.tooltip:after{ border-top-color: '+ props.color_botones  +' }';
      css += '.dot{ background-color: '+ props.color_botones  +' !important }';
      css += '.spinner{ border-left-color: '+ props.color_botones  +' }';
      css += '.to .bubble{ background-color: '+ props.color_fondo_burbuja +'; color: '+ props.color_texto_burbuja +' }';
      css += '.bubble a{ color:'+ props.color_botones  +' }';
      css += 'table td.active{color: '+ props.color_botones +'; border-bottom: 2px solid '+ props.color_botones +' !important; }';
      css += 'table td.active:hover{background-color: '+ props.color_botones +'; color: #FFF }';

      style.type = 'text/css';

      style.appendChild(document.createTextNode(css))
      head.appendChild(style);

      $('[data-header-image]').attr('src', props.imagen_cabecera);
      $('[data-subtitle]').text(props.subtitulo);

      this.setupInteractions();
    },
    setupFormContextId(){
      this.isCallingAjax = false;

      if($('.spinner-holder').length)
        $('.spinner-holder').remove();
    },
    setupInteractions(){
      const promise = $.ajax({
        type: 'POST',
        url: this.ajaxCall + this.apiInteractions,
        crossDomain: true,
        data: JSON.stringify({
          contextID: this.contextID,
          empresa: this.empresa
        }),
        contentType: 'application/json;charset=UTF-8',
        dataType: 'json'
      });

      promise.done((response) => {
        this.cid = response.data.cid;
        this.isCallingAjax = false;

        if($('.spinner-holder').length)
          $('.spinner-holder').remove();

        const $messageHolder = $('[data-conversation]');
        if(response.estado.codigo == 200){
          const calendarDates = response.data.array_fechas;

          $.each(response.data.conversacion, (index, element) => {
            const output = element.output,
                  input = element.input;     

            if(input.text)
              $messageHolder.append('<div class="conversation-bubble to animated fadeInUp"><p class="bubble">'+ input.text +'</p></div>');

            for(let i = 0; i < output.length; i++){
              const cleanedResponse = this.cleanResponse(output[i]);
                    
              $messageHolder.append('<div class="conversation-bubble from animated fadeInUp"><img class="rounded-img response-img" src="'+ this.customization.avatar_url +'" alt="logo" /><p class="bubble">'+ cleanedResponse.response +'</p></div>');
              
              if(cleanedResponse.buttonGroup.includes('END SESSION')){
                $('.form-wrapp').addClass('inactive');
                return false;
              }

              if(cleanedResponse.buttonGroup.length > 0 && (index + 1) == Object.keys(response.data.conversacion).length){
                let buttons = "";
                $.each(cleanedResponse.buttonGroup, (index, element) => {
                  buttons += '<button class="btn btn-big" data-msg="'+ element +'" data-func="sendButtonresponse">'+ element +'</button>';
                });
                $messageHolder.append('<div class="conversation-bubble align-center from animated fadeInUp">'+ buttons +'</div>');
                $messageHolder.animate({ scrollTop: $messageHolder.prop("scrollHeight")}, 300);
        
                this.eventsHandler( $messageHolder.find('.conversation-bubble:last-child').find('[data-func]') );
        
                $('.form-wrapp').addClass('inactive');
              }else{
                $('.form-wrapp').removeClass('inactive');
              }

              if(calendarDates && (index + 1) == Object.keys(response.data.conversacion).length){
                const calendario = this.getCalendar(calendarDates);
                $messageHolder.append(calendario);
                this.eventsHandler( $messageHolder.find('.calendar-holder').find('[data-func]') );
                $('.form-wrapp').addClass('inactive');
              }
            }
          });
          $messageHolder.animate({ scrollTop: $messageHolder.prop("scrollHeight")}, 300);
        }else{
          $messageHolder.append('<div class="conversation-bubble from animated fadeInUp"><p class="bubble">'+ response.estado.glosa +'</p></div>');
          $('.form-wrapp').addClass('inactive');
        }

      })
    },
    onLoadSetup(){},
    onScrollSetup(){},
    onResizeSetup(){},
    eventsHandler( $elements ){
        if( ! $elements.length ){ return; }

        $.each( $elements, ( index, elem ) => {
            const $item = $(elem),
                func = $item.data('func'),
                events = $item.data('event') ? $item.data('event') : 'click.handler';
            if( func && typeof( this[func] ) === 'function' ){
                $item.on( events, $.proxy( this[ func ], this ) );
                $item.data('delegated', true);
            } 
        });
    },
    getCleanedValue(value){
      const specialChars = "!¡@#$^&%*()+=-[]\/{}|:<>?¿,.";

      for(let i = 0; i < specialChars.length; i++){
        value = value.toString().replace(new RegExp("\\" + specialChars[i], 'gi'), '');
      }

      value = value.toLowerCase();
      value = value.replace(/á/gi,"a");
      value = value.replace(/é/gi,"e");
      value = value.replace(/í/gi,"i");
      value = value.replace(/ó/gi,"o");
      value = value.replace(/ú/gi,"u");
      value = value.replace(/ñ/gi,"n");

      return value;
    },
    cleanResponse(response){
      //Formato de respuesta: Texto ... ¿pregunta? %%%boton1%%% %%%boton2%%%
      let myRegexp = /^.*?\[\[\[(.*?)\]\]\]\(\(\((.*?)\)\)\).*?$/g,
            results = myRegexp.exec(response),
            url = results ? results[2] : null,
            urlText = results ? results[1] : null,
            btnSeparator = '$/^';

      let buttonGroup = [];

      if(url){
        response = response.replace('(((', '');
        response = response.replace(')))', '');
        response = response.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
        response = response.replace(urlText, '<a href="'+ url +'" target="_blank">'+ urlText +'</a>');
        response = response.replace('[[[', '');
        response = response.replace(']]]', '');
      }

      const splitedResponse = response.split(btnSeparator),
            answerGroup = splitedResponse[splitedResponse.length - 1],
            answerGroupSplit = answerGroup.split('%%%').clean("");
      
      if(answerGroupSplit.length > 1)      
        buttonGroup = answerGroupSplit[answerGroupSplit.length - 1].split('/');

      splitedResponse[splitedResponse.length - 1] = answerGroupSplit[0];

      response = splitedResponse;

      return {
        response,
        buttonGroup
      };

    },
    getMessageResponse(args){
      if(this.isCallingAjax){ return; }
      this.isCallingAjax = true;
      const promise = $.ajax({
        type: 'POST',
        url: this.ajaxCall + this.apiDialogue,
        crossDomain: true,
        data: JSON.stringify(args),
        contentType: 'application/json;charset=UTF-8',
        dataType: 'json'
      });

      promise.done((response) => {
        this.isCallingAjax = false;
      })

      return promise;
    },
    transversalResponseActions(response){
      const $messageHolder = $('[data-conversation]');

      $('.loader').remove();

      for(let i = 0; i < response.data.text.length; i++){
        const cleanedResponse = this.cleanResponse(response.data.text[i]),
              fechasCalendario = response.data.array_fechas;

        $.each(cleanedResponse.response, (index, element) => {
          $messageHolder.append('<div class="conversation-bubble from animated fadeInUp"><img class="rounded-img response-img" src="'+ this.customization.avatar_url +'" alt="Respuesta" /><p class="bubble">'+ element +'</p></div>');
          $messageHolder.animate({ scrollTop: $messageHolder.prop("scrollHeight")}, 300);
        });

        if(cleanedResponse.buttonGroup.includes('END SESSION')){
          $('.form-wrapp').addClass('inactive');
          return false;
        }

        if(cleanedResponse.buttonGroup.length > 0){
          let buttons = "";
          $.each(cleanedResponse.buttonGroup, (index, element) => {
            buttons += '<button class="btn btn-big" data-msg="'+ element +'" data-func="sendButtonresponse">'+ element +'</button>';
          });
          $messageHolder.append('<div class="conversation-bubble align-center from animated fadeInUp">'+ buttons +'</div>');
          $messageHolder.animate({ scrollTop: $messageHolder.prop("scrollHeight")}, 300);

          this.eventsHandler( $messageHolder.find('.conversation-bubble:last-child').find('[data-func]') );

          $('.form-wrapp').addClass('inactive');
        }else{
          $('.form-wrapp').removeClass('inactive');
        }

        if(fechasCalendario){
          const calendario = this.getCalendar(fechasCalendario);
          $messageHolder.append(calendario);
          this.eventsHandler( $messageHolder.find('.calendar-holder').find('[data-func]') );
          $('.form-wrapp').addClass('inactive');
        }
      }

    },
    getCalendar(fechasCalendario){
      const months = this.getCalendarData(fechasCalendario[0], fechasCalendario[fechasCalendario.length - 1]),
            monthData = this.getMonthData(months);

      let html = '';
      
      html += '<div class="align-center">';
      $.each(monthData, (index, data) => {
        const weeksData = data.calendar,
              monthNumber = data.monthNumber;

        html += '<div class="calendar-holder">';
        html += '<div class="calendar-title-holder">';
        html += '<span class="calendar-title">'+ data.month +' '+ data.year +'</span>';
        html += '<div class="calendar-table-holder">';
        html += '<table class="calendar-table">';
        html += '<thead>';
        html += '<tr>';
        html += '<th>Lun</th>';
        html += '<th>Mar</th>';
        html += '<th>Mie</th>';
        html += '<th>Jue</th>';
        html += '<th>Vie</th>';
        html += '<th>Sab</th>';
        html += '<th>Dom</th>';
        html += '</tr>';
        html += '</thead>';
        html += '<tbody>';
        $.each(weeksData, (i, week) => {
          html += '<tr>';
          $.each(week, (iw, ew) => {
            const activeClass = fechasCalendario.includes(ew.formatedDate) ? 'active' : '',
                  func = fechasCalendario.includes(ew.formatedDate) ? 'data-func="sendDate" data-date="'+ ew.formatedDate +'"' : '';

            if(monthNumber == ew.monthNumber)      
              html += '<td class="'+ activeClass +'" '+ func +'>'+ ew.date +'</td>';
            else
              html += '<td></td>';
          });
          html += '</tr>';
        });

        html += '</tbody>';
        html += '</table>';
        html += '</div>';
        html += '</div>'
        html += '</div>';
      });
      html += '</div>';

      return html;
    },
    sendDate(event){
      const $item = $(event.currentTarget),
            date = $item.data('date'),
            $messageHolder = $('[data-conversation]'),
            $form = $('.input-user-holder');

      $messageHolder.append('<div class="conversation-bubble to animated fadeInUp"><p class="bubble">'+ date +'</p></div>');
      if(!$('.loader').length)
        $form.append('<div class="loader"><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>');

      $('.calendar-holder').remove();

      const promise = this.getMessageResponse({ cid: this.cid, input: date, contextID: this.contextID, empresa: this.empresa });
      if(promise){
        promise.done((response) => {
          this.transversalResponseActions(response);
        });
      }
    },
    getMonthData(months){
      let monthData = [];
  
      months.forEach((month) => {
        const startDay = moment(month).clone().startOf('month').startOf('week'),
              endDay = moment(month).clone().endOf('month').endOf('week');
  
        let calendar = [];
        const index = startDay.clone();
        
        while (index.isBefore(endDay, 'day')) {
            calendar.push(
                new Array(7).fill(0).map(
                  (n, i) => {
                    const parsedDate = index.add(1, 'day').date();
                    return {
                      date: parsedDate,
                      dayName: moment(index).format('d'),
                      formatedDate: moment(index).format('YYYY-MM-DD'),
                      monthNumber: moment(index).format('MM'),
                      dateMoment: moment(index)
                    };
                  }
                )
            );
        }
  
        monthData.push({
          month: moment(month).format('MMM'),
          monthNumber: moment(month).format('MM'),
          year: moment(month).format('YYYY'),
          calendar
        });
      });
  
      return monthData;
    },
    getMonths(){
      return ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    },
    getCalendarData(start, end){
      const months = this.getMonths(),
            startDate = moment(start),
            endDate = moment(end);
    
      let monthsData = [];
    
      while (endDate > startDate || startDate.format('M') === endDate.format('M')){
        monthsData.push(startDate.format('YYYY-MM'));
        startDate.add(1,'month');
      }
    
      return monthsData;
    },
    getQueryParams(qs) {
      qs = qs.split('+').join(' ');

      var params = {},
          tokens,
          re = /[?&]?([^=]+)=([^&]*)/g;

      while (tokens = re.exec(qs)) {
          params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
      }

      return params;
    },
    //////HANDLERS
    sendButtonresponse(event){
      const $item = $(event.currentTarget),
            msg = $item.data('msg').toString(),
            $messageHolder = $('[data-conversation]'),
            $form = $('.input-user-holder');

      $messageHolder.append('<div class="conversation-bubble to animated fadeInUp"><p class="bubble">'+ msg +'</p></div>');
      if(!$('.loader').length)
        $form.append('<div class="loader"><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>');
      
      $item.parent().remove();

      const promise = this.getMessageResponse({ cid: this.cid, input: msg, contextID: this.contextID, empresa: this.empresa });
      if(promise){
        promise.done((response) => {
          this.transversalResponseActions(response);
        });
      }
    },
    submitMessage(event){
      event.preventDefault();
      const $form = $(event.currentTarget),
            $inputMessage = $form.find('[name="message"]'),
            $messageHolder = $('[data-conversation]'),
            inputValue = $inputMessage.val().toString();

      let isValidMsg = true;

      if(inputValue.length == 0){ return false; }

      $inputMessage.val('');
      $messageHolder.append('<div class="conversation-bubble to animated fadeInUp"><p class="bubble">'+ inputValue +'</p></div>');
      $messageHolder.animate({ scrollTop: $messageHolder.prop("scrollHeight")}, 300);

      if(!$('.loader').length)
        $form.append('<div class="loader"><span class="dot"></span><span class="dot"></span><span class="dot"></span></div>');

      if(this.validateString){
        isValidMsg = this.validateString.test(inputValue);
      }

      if(isValidMsg){
        const promise = this.getMessageResponse({ cid: this.cid, input: inputValue, contextID: this.contextID, empresa: this.empresa });
        if(promise){
          promise.done((response) => {
            this.validateString = false;
            this.transversalResponseActions(response);
            this.setupActionButton('');
          });
        }
      }
    },
    toggleElement(event){
      const $item = $(event.currentTarget),
            target = $item.data('toggle-target'),
            $target = $('[data-toggle-target-element="'+ target +'"]');

      $item.toggleClass('active');
      $target.toggleClass('active');

    },
    writeMessage(event){
      const $item = $(event.currentTarget),
            value = $item.val();

      this.setupActionButton(value);
    },
    setupActionButton(value){
      const $actions = $('.action-button-holder.actions'),
            $send = $('.action-button-holder.send');

      if(value.length){
        $actions.addClass('hide');
        $send.removeClass('hide');
      }else{
        $actions.removeClass('hide');
        $send.addClass('hide');
      }
    },
    selectRememberDay(event){
      const $item = $(event.currentTarget),
            value = parseInt($item.val(), 10),
            $form = $('.input-user-holder');
      
      if(value){
        if(!$('.spinner-holder').length)
          this.$body.append('<div class="spinner-holder"><div class="spinner">Loading...</div></div>');

        const promise = $.ajax({
          type: 'POST',
          url: this.apiRemember,
          crossDomain: true,
          data: JSON.stringify({
            dias_envio: value,
            contextID: this.contextID,
            empresa: this.empresa
          }),
          contentType: 'application/json;charset=UTF-8',
          dataType: 'json'
        });
  
        promise.done((response) => {
          $('.spinner-holder').remove();
          $('[data-toggle-target="concurrency-panel"]').trigger('click');
        })  
      }

    },
    submitContextId(event){
      event.preventDefault();
      const $form = $(event.currentTarget),
            value = $form.find('[name="context-id"]').val();

      if(value.length){
        this.isCallingAjax = true;
        this.$body.append('<div class="spinner-holder"><div class="spinner">Loading...</div></div>');

        const promise = $.ajax({
          type: 'POST',
          url: this.ajaxCall + '/CONFIRMACION_CONTACT_CODE',
          crossDomain: true,
          data: JSON.stringify({
            contact_code: value
          }),
          contentType: 'application/json;charset=UTF-8',
          dataType: 'json'
        });

        promise.done((response) => {
          if(response.estado.codigo == 200){
              this.contextID = value;
              $form.parents('.screen').removeClass('active');
              this.setupInteractions();
          }else{
            $('.spinner-holder').remove();
            $('.error-message').remove();
            $form.find('[name="context-id"]').after('<p class="error-message">'+ response.estado.glosa +'</p>');
          }
        });
        
        promise.fail((error) => {
          $('.spinner-holder').remove();
          $('.error-message').remove();
          $form.find('[name="context-id"]').after('<p class="error-message">Error de conexión, por favor intentelo nuevamente</p>');
        })
      }
    }
  };
  
  var Main = new window.handler();
  $document.ready(() => {Main.onReadySetup();});
  $window.on('load',() => { Main.onLoadSetup(); });

  $window.on({
      'scroll' : () => {Main.onScrollSetup();},
      'resize' : () => {Main.onResizeSetup();}
  });
} (window, document, jQuery));